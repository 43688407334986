#map {
  height: 100vh;
  width: 100%;
}

.mapboxgl-canvas:focus{
  outline:0;
}

.mapboxgl-popup-content{
  padding-bottom: 5px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  width: max-content;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip{
  border-bottom-color: rgba(255, 255, 255, 0.7);
  border-top-color: rgba(255, 255, 255, 0.7);
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip{
  border-bottom-color: rgba(255, 255, 255, 0.7);
  border-top-color: rgba(255, 255, 255, 0.7);
}