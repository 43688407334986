.GameStateOverlay{
    position: absolute;
    top:0;
    left: 0;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    pointer-events: none;
}
.GameStateOverlay:focus{
    outline:0;
}

.GameStateOverlayFooter{
    background-color: rgba(255, 255, 255, 0.7);
    pointer-events: auto;
    padding: 20px;
    border-radius: 25px;
    margin: 10px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}

.NumberDisplays{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
}

.NumberDisplay, .GameName{
    background-color: rgba(255, 255, 255, 0.7);
    padding: 10px;
    padding-top: 7px;
    margin: 10px;
    border-radius: 15px;
    height: fit-content;
}

.GameName b{
    font-size: 32px;
}

.GameStateOverlayHeader{
    display: flex;
    width: 100vw;
}

.GameStateOverlayFooter{
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
}

p{
    font-size: 18px;
}

b{
    font-size: 22px;
}

.gameOptions{
    display: flex;
    flex-direction: column;
    gap: 1vh;
}

.coloredCountriesOption{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.coloredCountriesOption input{
    vertical-align: middle;
    transform: scale(1.2);
}

.languageOption button{
    font-family: "Noto Color Emoji", system-ui;
    font-size: 1em;
    border: none;
    background: none;
}